import PdpAwardsCarouselController from '@/pdp-kenwood/Controllers/PdpAwardsCarouselController';
import PdpCompareCarouselController from '@/pdp-kenwood/Controllers/PdpCompareCarouselController';
import PdpConfiguratorCtaController from '@/pdp-kenwood/Controllers/PdpConfiguratorCtaController';
import PdpGalleryController from '@/pdp-kenwood/Controllers/PdpGalleryController';
import PdpPromoBannerController from '@/pdp-kenwood/Controllers/PdpPromoBannerController';
import PdpRecipesController from '@/pdp-kenwood/Controllers/PdpRecipesController';
import PdpRecommendationsCarouselController from '@/pdp-kenwood/Controllers/PdpRecommendationsCarouselController';
import PdpReviewsController from '@/pdp-kenwood/Controllers/PdpReviewsController';
import PdpSimilarCarouselController from '@/pdp-kenwood/Controllers/PdpSimilarCarouselController';
import PdpSpecificationsController from '@/pdp-kenwood/Controllers/PdpSpecificationsController';
import PdpStickyController from '@/pdp-kenwood/Controllers/PdpStickyController';
import { initPdpBVZeroReviews } from '@/utils/Controllers/Bazaarvoice/BVZeroReviewsController';
import { emitGtmListingImpression } from '@/utils/Controllers/GTM/Operations/ListingImpression';
import PdpFaqsController from '@/utils/Controllers/PdpFaqsController';
import PdpInfosPopupController from '@/utils/Controllers/PdpInfosPopupController';

document.addEventListener('DOMContentLoaded', () => {
  PdpReviewsController();
  PdpInfosPopupController();
  PdpStickyController();
  PdpPromoBannerController();
  initPdpBVZeroReviews();
  emitGtmListingImpression();
});

window.addEventListener('load', () => {
  PdpGalleryController();
  PdpAwardsCarouselController();
  PdpSpecificationsController();
  PdpRecommendationsCarouselController();
  PdpCompareCarouselController();
  PdpRecipesController();
  PdpFaqsController();
  PdpSimilarCarouselController();
  PdpConfiguratorCtaController();
});
